import React, { useContext } from 'react';
import {
	ArticleReferenceContainer,
	ArticleReferenceItem,
	ArticleReferenceLabel,
	ArticleReferenceLink,
	ArticleReferenceInfo,
} from './ArticleReference.dump';
import { getPostInfo } from './utils';
import { ReactComponent as Arrow } from '../../styles/assets/images/ui/icons/icon-arrowRight-black.svg';
import ArticleContext from './ArticleContext';

const ArticleReference: React.FC = () => {
	const { previousPost, nextPost } = useContext(ArticleContext);

	return (
		<ArticleReferenceContainer>
			{previousPost && (
				<ArticleReferenceItem>
					<ArticleReferenceLabel>Previous</ArticleReferenceLabel>
					<ArticleReferenceLink to={previousPost.fields.slug}>
						{previousPost.frontmatter.h1}
						<Arrow />
					</ArticleReferenceLink>
					<ArticleReferenceInfo>{getPostInfo(previousPost)}</ArticleReferenceInfo>
				</ArticleReferenceItem>
			)}
			{nextPost && (
				<ArticleReferenceItem>
					<ArticleReferenceLabel>Next</ArticleReferenceLabel>
					<ArticleReferenceLink to={nextPost.fields.slug}>
						{nextPost.frontmatter.h1}
						<Arrow />
					</ArticleReferenceLink>
					<ArticleReferenceInfo>{getPostInfo(nextPost)}</ArticleReferenceInfo>
				</ArticleReferenceItem>
			)}
		</ArticleReferenceContainer>
	);
};

export default ArticleReference;
