import React from 'react';
import { ArticleReferencePost, ArticleRelatedPost } from 'src/types';

export interface ArticleContextProps {
	nextPost: ArticleReferencePost | null;
	previousPost: ArticleReferencePost | null;
	recentPosts: ArticleRelatedPost[] | null;
}

const ArticleContext = React.createContext<ArticleContextProps>({
	nextPost: null,
	previousPost: null,
	recentPosts: null,
});

export default ArticleContext;
