import React, { useContext } from 'react';
import {
	ArticleSidebarList,
	ArticleSidebarHeadline,
	ArticleSidebarLink,
} from './ArticleSidebar.dump';
import ArticleContext from './ArticleContext';

const ArticleSidebarRelated: React.FC = () => {
	const { recentPosts } = useContext(ArticleContext);

	if (recentPosts === null) {
		return null;
	}

	return (
		<ArticleSidebarList>
			<ArticleSidebarHeadline>Related posts</ArticleSidebarHeadline>
			{recentPosts.map(el => (
				<li key={el.name}>
					<ArticleSidebarLink to={el.slug}>{el.name}</ArticleSidebarLink>
				</li>
			))}
		</ArticleSidebarList>
	);
};

export default ArticleSidebarRelated;
