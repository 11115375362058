import React from 'react';
import Layout from 'src/layout/Layout';
import { PostQueryProps } from 'src/types';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PostMdxProvider from 'src/components/markdown/PostMdxProvider';
import { ArticleContent } from 'src/components/BlogComponents/ArticleItem.dump';
import ArticleSidebar from 'src/components/BlogComponents/ArticleSidebar';
import { ArticleSidebarReference } from 'src/components/BlogComponents/ArticleSidebar.dump';
import ArticleSidebarRelated from 'src/components/BlogComponents/ArticleSidebarRelated';
import ArticleReference from 'src/components/BlogComponents/ArticleReference';
import { getPostInfo } from 'src/components/BlogComponents/utils';
import Intro from 'src/components/Intro/Intro';
import { IntroMessage } from 'src/components/Intro/Intro.dump';
import Article from 'src/components/BlogComponents/Article';

export interface BlogPostTemplateProps {
	data: PostQueryProps;
}

const PostLayout: React.FC<BlogPostTemplateProps> = ({ data }) => {
	const seoPage = {
		/* eslint-disable @typescript-eslint/camelcase */
		tab_title: data.frontmatter.tab_title,
		meta_description: data.frontmatter.meta_description,
		meta_keywords: data.frontmatter.meta_keywords,
		/*eslint-enable */
		article: {
			banner: data.frontmatter.banner,
			author: data.frontmatter.author,
			date: data.fields.date,
			mdate: data.mdate,
			wordcount: data.wordCount.words,
			headline: data.frontmatter.h1,
		},
	};

	return (
		<Layout seoPage={seoPage} css={'background: #f8fafd;'}>
			<PostMdxProvider>
				<Intro>
					<IntroMessage>
						<img src={data.frontmatter.banner} alt={data.frontmatter.h1} />
						<h1>{data.frontmatter.h1}</h1>
						<p>{getPostInfo(data)}</p>
					</IntroMessage>
				</Intro>
				<Article>
					<ArticleContent>
						<MDXRenderer>{data.body}</MDXRenderer>
					</ArticleContent>
					<ArticleSidebar>
						<ArticleSidebarReference>
							<ArticleSidebarRelated />
						</ArticleSidebarReference>
					</ArticleSidebar>
					<ArticleReference />
				</Article>
			</PostMdxProvider>
		</Layout>
	);
};

export default PostLayout;
