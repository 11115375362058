import styled from 'styled-components';
import { WIDTH_BREAKPOINT_LG } from 'src/constants';

export const h1 = styled.h1`
	font-family: 'Muller', sans-serif;
	font-size: 1.88rem;
	letter-spacing: 1px;
	line-height: 1.3;
	margin: 0;
	text-transform: uppercase;
`;

export const h2 = styled.h2`
	font-size: 24px;
	font-weight: bold;
	line-height: 32px;
	margin: 45px 0 20px;
`;

export const h3 = styled.h3`
	font-size: 18px;
	font-weight: bold;
	line-height: 24px;
	margin: 2em 0 0.6em;
`;

export const h4 = styled.h4`
	font-size: 14px;
	font-weight: 700;
	margin: 30px 0 15px;
	text-transform: uppercase;
`;

export const Paragraph = styled.p`
	font-size: 12pt;
	line-height: 1.75;
	margin-top: 20px;

	&:nth-child(1) {
		margin-top: 0;
	}
`;

export const Link = styled.a`
	color: #bb241d;
`;

export const List = styled.ol`
	list-style: initial;
	margin: 0;
	padding: 0 0 0 1.5rem;
`;

export const ListItem = styled.li`
	margin: 0.5rem;
`;

export const Code = styled.code`
	border-radius: 4px;
	padding: 10px 20px !important;
`;
export const InlineCode = styled.code`
	background-color: white;
	border: 1px solid #ebebeb;
	border-radius: 3px;
	color: #9e3621;
	font-size: 14px;
	line-height: 28px;
	padding: 4px 10px;
`;

export const Pre = styled.pre`
	font-family: Roboto Mono, sans-serif;
	font-size: 13px;

	&-::-webkit-scrollbar {
		border-radius: 4px;
		height: 10px;
		width: 10px;
	}

	&-::-webkit-scrollbar-thumb {
		background: #59717d;
		border: 3px solid #272c34;
		border-radius: 6px;
	}
`;

export const Img = styled.img`
	width: 100%;
`;

export const table = styled.table`
	border: 1px solid #ebebeb;
	border-radius: 3px;
	border-spacing: 0;
	margin-bottom: 30px;
	table-layout: auto;
	width: 100%;

	p {
		font-size: 14px;
		line-height: 20px;
		margin: 0;
		padding: 0;
	}
`;

export const td = styled.td`
	border-bottom: 1px solid #ebebeb;
	border-left: 1px solid #ebebeb;
	padding: 15px 20px;
	vertical-align: top;

	&:first-child {
		border-left: 0;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_LG}) {
		padding: 15px;
	}
`;

export const th = styled.th`
	background-color: #ebebeb;
	border-bottom: 1px solid #ebebeb;
	border-left: 1px solid #dddddd;
	padding: 15px 20px;
	text-align: left;
	vertical-align: top;

	&:first-child {
		border-left: 0;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_LG}) {
		padding: 15px;
	}
`;

export const tr = styled.tr`
	&:nth-child(odd) {
		background-color: #f7f7f7;
	}

	&:nth-child(even) {
		background-color: white;
	}

	&:last-child td {
		border-bottom: 0;
	}
`;

const PostComponents = {
	h1,
	h2,
	h3,
	h4,
	table,
	td,
	tr,
	th,
	p: Paragraph,
	a: Link,
	ol: List,
	ul: List,
	li: ListItem,
	inlineCode: InlineCode,
	code: Code,
	pre: Pre,
	img: Img,
};

export default PostComponents;
