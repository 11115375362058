import React from 'react';
import { graphql } from 'gatsby';
import { PostQueryProps, ArticleReferencePost, ArticleRelatedPost } from 'src/types';
import PostLayout from 'src/layout/PostLayout';
import ArticleContext from 'src/components/BlogComponents/ArticleContext';

export interface BlogPostTemplateProps {
	data: {
		mdx: PostQueryProps;
	};
	pageContext: {
		nextPost: ArticleReferencePost;
		previousPost: ArticleReferencePost;
		recentPosts: ArticleRelatedPost[];
	};
}

const BlogPostTemplate: React.FC<BlogPostTemplateProps> = ({ data, pageContext }) => {
	const providerValue = {
		nextPost: pageContext.previousPost,
		previousPost: pageContext.nextPost,
		recentPosts: pageContext.recentPosts,
	};

	return (
		<ArticleContext.Provider value={providerValue}>
			<PostLayout data={data.mdx} />
		</ArticleContext.Provider>
	);
};

export const pageQuery = graphql`
	query PostQuery($id: String) {
		mdx(id: { eq: $id }) {
			id
			body
			fields {
				date
				fileName
			}
			frontmatter {
				tab_title
				h1
				author
				meta_description
				meta_keywords
				banner
			}
			wordCount {
				words
			}
			mdate
		}
	}
`;

export default BlogPostTemplate;
