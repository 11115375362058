import styled from 'styled-components';
import { WIDTH_BREAKPOINT_MD, WIDTH_BREAKPOINT_SM } from 'src/constants';

export const IntroContentBlock = styled.div`
	align-items: center;
	background-position: center top;
	background-repeat: no-repeat;
	color: ${props => (props.theme === 'white' ? '#333' : 'white')};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 480px;
	padding-bottom: 80px;
	padding-top: 125px;

	h2 {
		color: ${props => (props.theme === 'white' ? '#bb241d' : 'white')};
	}

	img {
		margin-bottom: 20px;
		max-width: 480px;
		width: 100%;
	}
`;

export const IntroMessage = styled.div`
	max-width: 100%;
	text-align: center;
	width: 680px;

	h1 {
		font-family: 'Muller', sans-serif;
		font-size: 34px;
		font-weight: bold;
		letter-spacing: 1px;
		line-height: 1.3;
	}

	h2 {
		font-family: 'Muller', sans-serif;
		font-size: 34px;
		letter-spacing: 1px;
		line-height: 1.3;
	}

	p {
		font-size: 18px;
		line-height: 1.68;
		margin-top: 15px;
	}
`;

export const IntroCaption = styled.div`
	color: #f2f2f2;
	font-family: 'Muller', sans-serif;
	font-size: 150px;
	font-weight: bold;
	letter-spacing: 3px;
	line-height: 1;
	user-select: none;

	@media (min-width: ${WIDTH_BREAKPOINT_SM}) {
		font-size: 170px;
	}

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		font-size: 240px;
	}
`;

export const IntroContainer = styled.div`
	background: ${props => (props.theme === 'white' ? 'white' : '#23222a')};
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;
`;
