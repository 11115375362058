import styled from 'styled-components';
import { Link } from 'gatsby';
import { WIDTH_BREAKPOINT_MD } from 'src/constants';

export const ArticleReferenceContainer = styled.div`
	border-top: 1px solid #ebebeb;
	display: flex;
	justify-content: space-between;
	margin-top: 100px;
	order: 2;
	padding-top: 40px;
	width: 100%;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		order: 3;
	}
`;

export const ArticleReferenceItem = styled.div`
	display: flex;
	flex-direction: column;
	font-family: 'Muller', sans-serif;
	letter-spacing: 1px;
	line-height: 1.3;
	max-width: 45%;
`;

export const ArticleReferenceLabel = styled.div`
	color: #a6a6a6;
	font-size: 0.61rem;
	text-transform: uppercase;
`;

export const ArticleReferenceLink = styled(Link)`
	display: block;
	flex: 1 1;
	font-size: 0.77rem;
	margin-top: 15px;
	text-transform: uppercase;
	transition: 0.5s color;

	&:hover {
		color: #bb241d;
	}

	svg {
		margin-left: 5px;
	}
`;

export const ArticleReferenceInfo = styled.div`
	color: #a6a6a6;
	font-family: 'Lato', sans-serif;
	font-size: 0.66rem;
	letter-spacing: 0;
	margin-top: 5px;
`;
