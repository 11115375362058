import React from 'react';
import { IntroContainer, IntroContentBlock } from './Intro.dump';

export interface IntroProps extends React.HTMLAttributes<HTMLDivElement> {
	theme?: 'white' | 'dark';
}

const Intro: React.FC<IntroProps> = ({ className, children, theme, ...props }) => {
	return (
		<IntroContainer className={className} {...props} theme={theme}>
			<div className="wrapper">
				<IntroContentBlock theme={theme}>{children}</IntroContentBlock>
			</div>
		</IntroContainer>
	);
};

export default Intro;
